import React from "react";
import logo from "../images/open-membrane-database-logo-without-text.png";
import { Link } from "gatsby";

const Header = () => {
  const [isOpened, setIsOpened] = React.useState(false);
  return (
    <div className="container has-background-grey-light">
      <nav className="navbar" role="navigation" aria-label="main navigation">
        <div className="navbar-brand">
          <Link className="navbar-item" to="/">
            <img src={logo} alt="Open Membrane Database (OMD) logo" />
          </Link>
          <a
            role="button"
            className={["navbar-burger", isOpened && "is-active"].join(" ")}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setIsOpened(!isOpened)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className={["navbar-menu", isOpened && "is-active"].join(" ")}>
          <div className="navbar-end">
            <Link className="navbar-item" to="/reverse-osmosis-database">
              RO Database
            </Link>
            <div className="navbar-item has-dropdown is-hoverable">
              <div className="navbar-link">Membrane Science</div>
              <div className="navbar-dropdown">
                <Link className="navbar-item" to="/membrane-science">
                  Introduction to Membrane science
                </Link>
                <Link
                  className="navbar-item"
                  to="/standardization-of-membrane-performance-testing"
                >
                  Standardization of membrane performance testing
                </Link>
                <Link className="navbar-item" to="/concentration-polarization">
                  Concentration polarization
                </Link>
              </div>
            </div>
            <div className="navbar-item has-dropdown is-hoverable">
              <Link to='/calculators' className="navbar-link">Calculators</Link>
              <div className="navbar-dropdown">
                <Link
                  className="navbar-item"
                  to="/calculators/membrane-performance-calculator"
                >
                  Membrane performance
                </Link>
                <Link
                  className="navbar-item"
                  to="/calculators/concentration-polarization-calculator"
                >
                  Concentration polarization
                </Link>
                <Link
                  className="navbar-item"
                  to="/calculators/osmotic-pressure-calculator"
                >
                  Osmotic pressure
                </Link>
                <Link
                  className="navbar-item"
                  to="/calculators/common-unit-converters"
                >
                  Common unit converters
                </Link>
              </div>
            </div>
            <Link className="navbar-item" to="/news">
              News
            </Link>
            <Link
              className="navbar-item"
              to="/about-the-open-membrane-database"
            >
              About
            </Link>
            <div className="navbar-item">
              <Link className="is-primary button" to="/submit-a-membrane">
                Submit a membrane
              </Link>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
