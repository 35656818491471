import React, { createContext, useReducer } from "react";

const initialState = {};

const reducer = (state, action) => {
  switch (action.type) {
    case "update":
     let newState = {
        ...state,
        ...action.item,
      };
      for (const output of action.outputs) {
        const result = output.calculate(newState);
        if (!isNaN(result)) {
          newState = {
            ...newState,
            ...{ [output.name]: Number(result) },
          }
        }
      }
      return newState;
    default:
      throw new Error();
  }
};
const CalculatorsContext = createContext(initialState);
export default CalculatorsContext;

export const CalculatorsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const update = (item, outputs) => {
    dispatch({
      type: "update",
      item: item,
      outputs: outputs
    });
  };
  return (
    <CalculatorsContext.Provider value={{ values: state, update }}>
      {children}
    </CalculatorsContext.Provider>
  );
};
