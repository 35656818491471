import React from "react";
import "../styles/styles.scss";
import Footer from "./footer";
import Header from "./header";
import Seo from "./seo";
import { MathJaxContext } from "better-react-mathjax";
import { CalculatorsContextProvider } from "./calculators/calculators-context";

const Layout = ({ children }) => {
  return (
    <MathJaxContext>
      <CalculatorsContextProvider>
        <Seo />
        <Header />
        {children}
        <Footer />
      </CalculatorsContextProvider>
    </MathJaxContext>
  );
};

export default Layout;
