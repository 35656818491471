import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link } from "gatsby"
import React from "react"
import logo from "../images/open-membrane-database-logo.png"


const Footer = () => {

    return (
        <footer className="footer has-background-primary-light" style={{ borderTop: '1px solid #e3e3e3' }}>
            <div className="container">
                <div className="columns is-centered is-vcentered is-variable is-8">
                    <div className="column is-5 has-text-right has-text-centered-mobile mb-5">
                        <h2 className='title is-5'>Stay updated</h2>
                        <ul className="mb-3">
                            <li className="mb-3">✓ New releases</li>
                            <li className="mb-3">✓ New membranes</li>
                            <li className="mb-3">✓ OMD News</li>
                            <li className="mb-3">✓ Tools updates</li>
                        </ul>
                        <a className="button is-primary" href="https://openmembranedatabase.us7.list-manage.com/subscribe?u=dae8e4cc63f5ddd81ffbc76a0&id=35ce95d20a">
                            <span className="icon">
                                <FontAwesomeIcon icon={faArrowRight} />
                            </span>
                            <span>
                                Join our mailing list
                            </span>
                        </a>
                    </div>
                    <div className="column is-2 has-text-centered mb-5">
                        <img src={logo} alt='Open Membrane Database (OMD) logo' />
                    </div>
                    <div className="column is-5 mb-5 has-text-centered-mobile">
                        <h2 className='title is-5'>Browse the website</h2>
                        <ul>
                            <li className="mb-3">
                                Discover the <Link to='/reverse-osmosis-database'><u>reverse osmosis database</u></Link>
                            </li>
                            <li className="mb-3">
                                Learn more about <Link to='/membrane-science'><u>membrane science</u></Link>
                            </li>
                            <li className="mb-3">
                                Use our <Link to='/calculators'><u>calculators</u></Link>
                            </li>
                            <li className="mb-3">
                                Read about and contact the <Link to='/about-the-open-membrane-database'><u>Open Membrane Database</u></Link>
                            </li>
                            <li className="mb-3">
                                <Link to='/submit-a-membrane'><u>Submit new membranes</u></Link> with a dedicated form
                            </li>
                            <li className="mb-3">
                                Read all latest <Link to='/news'><u>news and releases</u></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="has-text-centered mt-6">
                <p>
                    &copy; {(new Date()).getFullYear()} Open Membrane Database
                </p>
                <p className="is-size-7">
                    The Open Membrane Database (OMD) is licensed under <a href="https://creativecommons.org/licenses/by-nc/4.0/deed.en">CC BY-NC 4.0</a>
                </p>
            </div>
        </footer>
    )
}

export default Footer